import { Link } from 'react-router-dom';
import { useState } from 'react';

export default function Nav(props) {
  const [mobileToggled, setMobileToggled] = useState(false);

  const plainlySetMainNav = (navItem) => {
    props.setMainNav(navItem.name);
  }

  const searchToggle = (navItem) => {
    props.toggleNewLocationSearch();
    plainlySetMainNav(navItem)
  }

  const navItems = [
    { name: "Hilfe", icon: "dg-info-icon", destination: "/hilfe", onClickFunction: plainlySetMainNav },
    { name: "Empfangswege", icon: "dg-ausspielwege-icon", destination: "/empfangswege", onClickFunction: plainlySetMainNav },
    { name: "Suche", icon: "dg-suche-icon", destination: "/", onClickFunction: searchToggle },
  ];
  return (
    <div>
      <section className="dg-panel-navigation" data-dg="dg-panel-navigation" style={{"display": mobileToggled?'unset':'none'}}>
        <ul data-dg="tab">
          {navItems.map((navItem,i) => {
            return (
              <Link key={i} to={navItem.destination}>
                <li
                  key={navItem.name}
                  onClick={() => {
                    navItem.onClickFunction(navItem);
                    setMobileToggled(false);
                  }}>
                  <div className="dg-nav-box">
                    <div className={"dg-nav-icon " + navItem.icon}></div>
                    <p className="dg-nav-name">{navItem.name}</p>
                  </div>
                </li>
              </Link>
            );
          })}
          <div className="clear"></div>
        </ul>
      </section>
      <section className="dg-title">
        <nav className="dg-container">
          <a href="https://www.mdr.de/" rel="noreferrer" target="_blank">
            <strong className="dg-main-logo"></strong>
          </a>
          <div className="tagged-plz">
            <p data-dg="dg-insert-zip">
              <span className="dg-plz-lk">
                {Object.keys(props.place).length !== 0 ? (props.place.districtInfo === null ? '' : props.place.districtInfo + " " + props.place.districtName + " ") : ''}
              </span>
              <span className="dg-plz-info">
                {Object.keys(props.place).length !== 0 ? props.place.zip : '' }
              </span>
              <span className="dg-city-first">
                {Object.keys(props.place).length !== 0 ? props.place.place : "Frequenzsuche" }
              </span>
            </p>
          </div>
          <ul className="dg-nav-menu" data-dg="tab">
            {navItems.map((navItem,i) => {
              return (
                <Link key={i} to={navItem.destination}>
                  <li
                    onClick={() => navItem.onClickFunction(navItem)}
                    key={navItem.name}
                    className={
                      navItem.name === 'Suche' ? 
                        props.mainNav === navItem.name
                          ? "dg-extra-menu-button dg-buttons active dg-active"
                          : "dg-extra-menu-button dg-buttons"
                        :
                        props.mainNav === navItem.name
                        ? "dg-buttons active dg-active"
                        : "dg-buttons"
                    }
                  >
                    <div className="dg-nav-box">
                      <div className={"dg-nav-icon " + navItem.icon}></div>
                      <p className="dg-nav-name">{navItem.name}</p>
                    </div>
                  </li>
                </Link>
              );
            })}
            <li className="dg-buttons dg-menu-button">
              <button
                className="dg-button menu-button"
                onClick={()=>setMobileToggled(!mobileToggled)}
              >
                <div className="df"></div>
                <div></div>
                <div></div>
              </button>
            </li>
          </ul>
        </nav>
      </section>
    </div>
  );
}
